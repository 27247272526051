import React, { useRef, useEffect } from "react"
import Link from "gatsby-link"
import AnimatedHeadline from "../components/AnimatedHeadline"
import Footer from "../components/footer"
import Kontakt from "../components/kontakt"
import { gsap, TimelineMax } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import SEO from "../components/seo"
import UXIcon from "../svg/ux-iterativ.svg"
import SocialIcon from "../svg/social-icon.svg"
import WebsiteIcon from "../svg/website-icon.svg"
import ogImageServices from "../images/og-image/og-image-services.png"
import ProcessMobile from "../svg/process-mobile.svg"
import ProcessSVG from "../svg/process.svg"
import scrollToElement from "scroll-to-element"
gsap.registerPlugin(ScrollTrigger)

export default function Services() {
  let overlay = useRef(null)



  useEffect(() => {
    overlay.style.height = "1149px"
    gsap.registerPlugin(ScrollTrigger)

    gsap.to(".cb-ux-process-bg-overlay", {
      scrollTrigger: {
        trigger: ".cb-ux-process-bg-overlay",

        scrub: 0.4,

        /*  markers: true, */
        start: "-=350 center",
        end: "1400 center",
      },
      y: "100%",
    })


   
  }, [])

  let mobile = false

  if (typeof window !== "undefined" && window.innerWidth < 1024) {
    mobile = true
  }
  /*   let windowWidth = window.innerWidth
  if (window !== "undefined" && window.innerWidth < 768) {
    headlineText =
      "Verschaffen Sie sich einen Überblick über unsere Leistungen und lernen Sie unsere Arbeitsweise kennen."
  } */

  return (
    <>
      <SEO
        title="Services. Was Cuuno ihnen bietet."
        description="Alles was Sie schon immer über ihre Freunde bei Cuuno wissen wollten. Verschaffen Sie sich einen Überblick
        über unsere Leistungen und lernen Sie unsere Arbeitsweise kennen."
        image={ogImageServices}
        defaultTitle="Services - Cuuno"
        titleTemplate="Services"
      />
      <div className="cb-container">
        <div className="cb-herosection">
          <div className="cb-herosection-wrap">
            <AnimatedHeadline
              scrollTo={"#vorgehen"}
              offset={-200}
              mobileText="Verschaffen Sie sich einen Überblick über unsere Leistungen und lernen Sie unsere Arbeitsweise kennen."
              desktopText={[
                "Verschaffen Sie sich einen Überblick",
                "über unsere Leistungen und lernen",
                "Sie unsere Arbeitsweise kennen.",
              ]}
            />
          </div>
        </div>
      </div>

      <section className="cb-services">
        <div class="cb-container">
          <section id="vorgehen" className="cb-process">
            {/*      <div class="cb-subheader">
              <h1>Our approach</h1>
            </div>
 */}
            <div class="cb-header">
              <h1>Unser Vorgehen</h1>
            </div>

            <section className="cb-ux-process">
              <div ref={el => (process = el)} class="cb-ux-process-bg">
                {mobile && <ProcessMobile />}
                {!mobile && <ProcessSVG />}

                <div
                  ref={el => (overlay = el)}
                  className="cb-ux-process-bg-overlay"
                ></div>
              </div>
              <div data-aos="fade-up" className="cb-ux-process-col">
                <div class="cb-process-title">
                  <h2>1. Phase: Ihre Idee</h2>
                </div>
                <p className="cb-process-paragraph">
                  Alles beginnt mit Ihrer Idee - wir müssen nachvollziehen
                  können, was Sie sich vorstellen. Haben Sie bereits eine
                  detaillierte Vision oder möchten Sie, dass wir völlig kreativ
                  sind?
                </p>
              </div>
              <div data-aos="fade-up" className="cb-ux-process-col">
                <div class="cb-process-title">
                  <h2>2. Phase: Planen und Struktuieren </h2>
                  <p className="cb-process-paragraph">
                    Nachdem wir nun mehr darüber erfahren haben, was Ihnen
                    vorschwebt, gehen wir zur Strukturierung des Produkts über.
                    Wir erstellen User-Stories und Personas und klären die
                    Nutzeranforderungen an die Website.
                  </p>
                </div>
              </div>

              <div data-aos="fade-up" className="cb-ux-process-col">
                <div class="cb-process-title">
                  <h2>3. Phase: Design </h2>
                  <p className="cb-process-paragraph">
                    Nach der Planungsarbeit ist es an der Zeit das 'Look and
                    Feel' Ihrer Website zu erstellen. Hierfür erstellen wir
                    Mockups, Wireframes und Prototypen. Wir entwickeln das
                    Design in einem iterativen Prozess, bei dem die
                    Kommunikation zwischen uns und unseren Kunden im Mittelpunkt
                    steht. Dadurch soll gewährleistet werden, dass das
                    endgültige Aussehen der Website Ihren Erwartungen und
                    Wünschen entspricht.
                  </p>
                </div>
              </div>

              {/* 4*/}

              <div data-aos="fade-up" className="cb-ux-process-col">
                <div class="cb-process-title">
                  <h2>4. Phase: Umsetzung </h2>
                  <p className="cb-process-paragraph">
                    Wenn das Konzept und das Design steht, können wir zur
                    Entwicklung übergehen. Auch hier arbeiten wir iterativ,
                    weshalb die Umsetzung in Sprints geplant wird.
                  </p>
                </div>
              </div>
              {/* 5*/}

              <div
                data-aos="fade-up"
                data-offset="550"
                className="cb-ux-process-col"
              >
                <div class="cb-process-title">
                  <h2>5. Phase: Launch </h2>
                  <p className="cb-process-paragraph">
                    Am Ende werfen wir gemeinsam einen letzten Blick auf die
                    Website. Wenn wir alles realisieren konnten was ihnen
                    vorschwebte und die Lösung alle Anforderungen erfüllt, wird
                    die Webseite gelauncht.
                  </p>
                </div>
              </div>
            </section>
          </section>
        </div>
        <section className="cb-offer">
          <div class="cb-container">
            {/*    <div class="cb-subheader">
              <h1>Services</h1>
            </div> */}

            <div class="cb-offer-header cb-header">
              <h1>
                Welche Leistungen <br />
                wir anbieten
              </h1>
            </div>

            <div class="cb-offer-grid">
              <div class="cb-offer-grid-col">
                {/* UX / UI */}
                <div class="cb-offer-icon">
                  <UXIcon />
                </div>

                <div class="cb-offer-title">
                  <h4>UX und UI</h4>
                </div>
                <div class="cb-offer-paragraph">
                  <p>
                    Als zertifizierte UX-Professionals wissen wir was gute
                    Usability und User Experience ausmacht. Neben
                    Usability-Testing bieten wir u.a. Usability-Reviews an, um
                    die Nutzerfreundlichkeit ihrer Anwendung sicherzustellen.
                  </p>
                </div>
                <div class="cb-offer-tabs">
                  <div className="cb-offer-tabs-item">
                    <p>Usability-Test</p>
                  </div>
                  <div className="cb-offer-tabs-item">
                    <p>Usablility-Review</p>
                  </div>
                  <div className="cb-offer-tabs-item">
                    <p>Design Konzept</p>
                  </div>
                  <div className="cb-offer-tabs-item">
                    <p>Animation</p>
                  </div>

                  <div className="cb-offer-tabs-item">
                    <p>Vektorbasierte Illustration</p>
                  </div>
                </div>
              </div>
              <div class="cb-offer-grid-col">
                <div class="cb-offer-icon">
                  <WebsiteIcon />
                </div>
                <div class="cb-offer-title">
                  <h4>Web</h4>
                </div>
                <div class="cb-offer-paragraph">
                  <p>
                    Wir designen um zu vereinfachen. Wir verfolgen eine
                    Ideologie der Qualität und Einfachheit, um langlebige
                    Lösungen zu erreichen. Unterstützt durch moderne
                    Technologien bauen wir Webseiten, die die Funktionalität
                    verbessern, zuverlässig bleiben und sich mit der Zeit
                    weiterentwickeln.
                  </p>
                </div>
                <div class="cb-offer-tabs">
                  <div className="cb-offer-tabs-item">
                    <p>Webseite</p>
                  </div>
                  <div className="cb-offer-tabs-item">
                    <p>E-Commerce / Online-Shop</p>
                  </div>
                  <div className="cb-offer-tabs-item">Landingpage</div>
                  <div className="cb-offer-tabs-item">
                    <p>Dashboard</p>
                  </div>
                  <div className="cb-offer-tabs-item">
                    <p>CMS</p>
                  </div>
                </div>
              </div>

              <div class="cb-offer-grid-col">
                {/* UX / UI */}

                <div class="cb-offer-icon">
                  <SocialIcon />
                </div>
                <div class="cb-offer-title">
                  <h4>Social Media</h4>
                </div>
                <div class="cb-offer-paragraph">
                  <p>
                    Präsenz und Engagement in sozialen Netzwerken ist
                    unverzichtbar für den Erfolg im Web. Wir entwickeln für Sie
                    und Ihr Produkt die passenden Strategie aus Tools und
                    Maßnahmen.
                  </p>
                </div>
                <div class="cb-offer-tabs">
                  <div className="cb-offer-tabs-item">
                    <p>Strategien</p>
                  </div>
                  <div className="cb-offer-tabs-item">
                    <p>Content-Erstellung</p>
                  </div>
                  <div className="cb-offer-tabs-item">
                    <p>Content-Management</p>
                  </div>
                  <div className="cb-offer-tabs-item">
                    <p>Redaktion</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
      <Kontakt />
      <Footer />
    </>
  )
}
